<template>
    <div>
        <hb-modal size="medium" :title="formattedWorkflowName" v-model="dialog" confirmation @close="$emit('close')">
            <template v-slot:content>
                <div class="py-4 px-6">
                    Turning off the workflow at space type / {{ template == 'corporate' ? 'state' : 'property' }} level would pause all the current delinquencies and
                    will not initiate any new delinquencies for any tenant that goes delinquent. If this is turned back
                    on then all the pause delinquencies would be resumed and the delinquent tenants with no
                    delinquencies will be enrolled into the delinquency process once the next rent invoice is issued.
                </div>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="$emit('ok')" :disabled="disabled" :loading="disabled">Turn {{
                    !!workflow.assigned == true ? 'off' : 'on' }} Automation</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
import AUTOMATION from '@/constants/automation.js';
import ADDRESS from '@/constants/address.js';
import { countryStateCityMixin } from '@/mixins/countryStateCityMixin.js';
export default {
    name: 'WorkflowToggle',
    data() {
        return {};
    },
    mixins: [countryStateCityMixin],
    created() {
        this.AUTOMATION = AUTOMATION;
        this.setCountiesAndStates();
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        formattedWorkflowName() {
            return this.workflow.full_path ? this.formatWorkflowName(this.workflow.full_path) : "Warning";
        }
    },
    props: {
        workflow:
        {
            type: Object,
            default() {
                return {};
            }
        },
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        selectedAutomationType: {
            type: Object,
            default: () => ({})
        },
        template: {
            type: String,
            default() {
                return 'corporate';
            }
        },
    },
    methods: {
        setCountiesAndStates() {
            this.setCountriesListEvent();
            this.setStatesListEvent(ADDRESS.DEFAULT_COUNTRY);
        },
        formatWorkflowName(workflow_full_path) {
            if (this.selectedAutomationType.name.toLowerCase() === AUTOMATION.DELINQUENCY_AUTOMATION.toLowerCase()) {
                const parts = workflow_full_path.split(' > ');
                const state = parts[0];
                const formattedState = this.stateListRawMixin.find(item => item.isoCode === state)?.name || state;
                return [formattedState, ...parts.slice(1)].join(' > ');
            }
            return workflow_full_path;
        }
    }
}
</script>

<style scoped></style>